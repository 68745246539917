import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Search.css';
import logo from "../../asset/EssilorLuxottica.svg";
import searchIcon from '../../asset/search-icon.png';
import CofProjectDati, { Project } from '../TableAPI/CofProjectDati';
import { BASE_API } from '../TableAPI/Constant';

const Search = () => {
  // State per gestire lo stato della tabella
  const [showTable, setShowTable] = useState(false);
  // State per memorizzare i dati recuperati dall'API
  const [dataCof, setDataCof] = useState<Project[]>([]);
  // State per memorizzare i dati selezionati dall'utente
  const [editableData, setEditableData] = useState<Project[]>([]);
  // State per memorizzare l'ID del progetto selezionato
  const [projectId, setProjectId] = useState<string>('');
  // State per memorizzare il valore dell'input di ricerca
  const [inputValue, setInputValue] = useState<string>('');
  // State per memorizzare le suggerimenti di ricerca
  const [suggestions, setSuggestions] = useState<Project[]>([]);
  // State per memorizzare eventuali messaggi di errore
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Effetto per recuperare i dati iniziali dall'API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const mainProjectId = sessionStorage.getItem('mainProjectId') || '';
        const response = await axios.get(`${BASE_API}/cofProjects/current/${mainProjectId}/`);
        const responseData = response.data;
        if (responseData && responseData.data) {
          setDataCof(responseData.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Funzione per gestire il cambiamento dell'input di ricerca
  const handleInputChange = (value: string) => {
    setInputValue(value);
    if (value.trim() === '') {
      setSuggestions([]);
      setErrorMessage('');
      setShowTable(false); // Nascondi la tabella quando la barra di ricerca è vuota
      return;
    }
    const filteredSuggestions = dataCof.filter(project =>
      (project.form_details.NAME && project.form_details.NAME.toLowerCase().includes(value.toLowerCase())) ||
      project.project_id.toString().includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
    if (filteredSuggestions.length === 0) {
      setErrorMessage('No suggestions found');
    } else {
      setErrorMessage('');
    }
  }

  // Funzione per gestire la selezione di un suggerimento di ricerca
  const handleSelectSuggestion = (selectedProject: Project) => {
    setInputValue(`${selectedProject.form_details.NAME} (${selectedProject.project_id})`);
    setEditableData([selectedProject]);
    setShowTable(false); // Nascondi temporaneamente la tabella per forzare un re-render
    setProjectId(selectedProject.project_id.toString());
    setSuggestions([]);
    // Ritarda l'aggiornamento dello stato per assicurarsi che la tabella venga re-renderizzata
    setTimeout(() => setShowTable(true), 0);
  }

  // Funzione per gestire il reindirizzamento alla pagina di ricerca quando si fa clic sul logo
  const handleLogoClick = () => {
    // Redirect alla pagina di ricerca
    window.location.href = '/search';
  };

  return (
    <div>
      {/* Header con il logo e il link per l'upload */}
      <div className='top'>
      <img src={logo} alt="" style={{ width: "483px", height: "100px", cursor: "pointer" }} onClick={handleLogoClick} />
        <a className='up' href='/upload' role="button">UPLOAD</a>
      </div>
      {/* Barra di ricerca */}
      <div className='searchbar'>
        <input
          className="search"
          placeholder="Type Project Name or Project Code"
          value={inputValue}
          onChange={(e) => handleInputChange(e.target.value)}
          style={{ paddingLeft: '10px' }} 
        />
        {/* Mostra eventuali messaggi di errore */}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {/* Mostra i suggerimenti di ricerca */}
        {suggestions.length > 0 && (
          <div className='suggestions'>
            {suggestions.map(project => (
              <div
                key={project._id}
                className='suggestion-item'
                onClick={() => handleSelectSuggestion(project)}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) => (e.target as HTMLElement).style.backgroundColor = '#f0f0f0'} 
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) => (e.target as HTMLElement).style.backgroundColor = 'transparent'} 
              >
                {project.form_details.NAME} ({project.project_id})
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Mostra la tabella dei progetti */}
      {showTable && <CofProjectDati projectId={projectId} />}
    </div>
  );
};

export default Search;
