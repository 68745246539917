import React, { useEffect, useState, useCallback } from "react";
import { BASE_API } from "./Constant";
import axios from "axios";
import * as XLSX from "xlsx";

interface FormDetails {
  ADDRESS: string;
  "AFS1 #": string;
  "BRAND SUB": string;
  CITY: string;
  "COF COMPLETION DATE": string;
  "CONSTRUCTION PUNCH DATE": string;
  "CONSTRUCTION START DATE": string;
  COUNTRY: string;
  "DESIGN TYPE": string;
  "FMS1 #": string;
  GC: number;
  NAME: string;
  "OVER ALL AREA [sqf]": number;
  "PROJECT ID": number;
  "PROJECT MANAGER": string;
  "RETAIL AREA [sqf]": number;
  SCOPE: string;
  STATE: string;
  "STORE #": string;
  "STORE OPEN DATE": string;
  "STORE TYPE": string;
  UNION: number;
  ZIP: string;
}

interface Item {
  [key: string]: any;
  ACCOUNT: string;
  "ARTICLE #": number;
  "COST [$]": number;
  QTY: number;
  TAX: number;
  "TOT [$]": number;
  "UNIT COST [$]": number;
  "VENDOR #": number;
  "WBS #": string;
  details: any[];
}

interface VersionDetails {
  _id: string;
  datetime_extraction: string;
  extractor_version: number;
  form_details: FormDetails;
  items: Item[];
  main_project_id: string;
  project_id: number;
  version: number;
}

interface ArchiveVersion {
  _id: string;
  datetime_extraction: string;
  extractor_version: number;
  form_details: FormDetails;
  items: Item[];
  main_project_id: string;
  project_id: number;
  version: number;
}

export interface DataResponse {
  code: number;
  data: {
    current_version: VersionDetails;
    archive_versions: ArchiveVersion[];
  };
  message: string;
}

export interface Project {
  _id: string;
  datetime_extraction: string;
  extractor_version: number;
  form_details: FormDetails;
  items: Item[];
  main_project_id: string;
  project_id: number;
  version: number;
}

interface CostSumDataProps {
  projectId: string;
}

const CofProjectDati: React.FC<CostSumDataProps> = ({ projectId }) => {
  const [data, setData] = useState<DataResponse | null>(null);
  const [editableFormDetails, setEditableFormDetails] = useState<{ [version: number]: Partial<FormDetails> }>({});
  const [editableItems, setEditableItems] = useState<{ [version: number]: Item[] }>({});
  const [newFormDetail, setNewFormDetail] = useState<Partial<FormDetails> | null>(null);
  const [newItem, setNewItem] = useState<Partial<Item> | null>(null);
  const [expanded, setExpanded] = useState(false);
  const [rowsToShow, setRowsToShow] = useState(5);
  const [selectedVersions, setSelectedVersions] = useState<number[]>([]);
  const [newDetails, setNewDetails] = useState<{ name: string; value: string }[]>([]);
  const [isVersionSelected, setIsVersionSelected] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const mainProjectId = sessionStorage.getItem("mainProjectId") || "";
      const response = await axios.get(`${BASE_API}/cofProjects/current/${mainProjectId}/data/${projectId}/`);
      const jsonData = response.data;

      // the format changed: now each item is not a flat object but, each item has a list of details besides the key fields ACCOUNT, ARTICLE #, VENDOR #
      // e.g.
      //    {
      //   "ACCOUNT": "AFS1",
      //   "VENDOR #": 1045376,
      //   "ARTICLE #": 1009461,
      //   "details": [
      //     {
      //       "WBS #": "01.02.11",
      //       "QTY": 4869.43,
      //       "UNIT COST [$]": 1,
      //       "PO #": null,
      //       "TAX": 0.068
      //     },
      //     {
      //       "WBS #": "01.02.11",
      //       "QTY": 2345.67,
      //       "UNIT COST [$]": 1,
      //       "PO #": null,
      //       "TAX": 0.068
      //     },
      //   ]
      // }
      // so we transform the data to the old format:
      // for each of the details we create a full item with the common key fields and teh details
      // e.g.
      //  {
      //   "ACCOUNT": "AFS1",
      //   "VENDOR #": 1045376,
      //   "ARTICLE #": 1009461,
      //   "WBS #": "01.02.11",
      //   "QTY": 4869.43,
      //   "UNIT COST [$]": 1,
      //   "PO #": null,
      //   "TAX": 0.068
      //  },
      //  {
      //   "ACCOUNT": "AFS1",
      //   "VENDOR #": 1045376,
      //   "ARTICLE #": 1009461,
      //   "WBS #": "01.02.11",
      //   "QTY": 2345.67,
      //   "UNIT COST [$]": 1,
      //   "PO #": null,
      //   "TAX": 0.068
      //  }, ...
      // so that the table can be rendered as before
      jsonData.data.current_version.items = jsonData.data.current_version.items.reduce((acc: Item[], item: Item) => {
        item.details?.forEach((detail: any) => {
          acc.push({
            ...item,
            ...detail
          });
        });
        return acc;
      }, []);

      //apply this transform to all the archive versions, if present
      jsonData.data.archive_versions = jsonData.data.archive_versions.map((version: ArchiveVersion) => {
        version.items = version.items.reduce((acc: Item[], item: Item) => {
          item.details?.forEach((detail: any) => {
            acc.push({
              ...item,
              ...detail
            });
          });
          return acc;
        }, []);
        return version;
      });

      // now process the data as before

      setData(jsonData);

      const formDetails = jsonData.data.archive_versions.reduce(
        (acc: { [version: number]: Partial<FormDetails> }, version: ArchiveVersion) => {
          acc[version.version] = version.form_details;
          return acc;
        },
        { [jsonData.data.current_version.version]: jsonData.data.current_version.form_details }
      );
      setEditableFormDetails(formDetails);

      const items = jsonData.data.archive_versions.reduce(
        (acc: { [version: number]: Item[] }, version: ArchiveVersion) => {
          acc[version.version] = version.items;
          return acc;
        },
        { [jsonData.data.current_version.version]: jsonData.data.current_version.items }
      );
      setEditableItems(items);

      setSelectedVersions(
        [jsonData.data.current_version.version, ...jsonData.data.archive_versions.map((v: { version: any; }) => v.version)]
      );

      console.log("Dati caricati:", { formDetails, items });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [projectId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSave = async () => {
    const currentVersion = data?.data.current_version;

    if (currentVersion) {
      const itemsToSave = newItem ? [...editableItems[currentVersion.version], newItem] : editableItems[currentVersion.version];
      const formDetailsToSave = newFormDetail ? { ...editableFormDetails[currentVersion.version], ...newFormDetail } : editableFormDetails[currentVersion.version];

      const newDetailsToSave = newDetails.reduce((acc, detail) => {
        (acc as any)[detail.name] = detail.value;
        return acc;
      }, {} as Partial<FormDetails>);

      // change back to the new format aggregating the items by keys and listing the details in a subcollection
      //from: e.g.
      //  {
      //   "ACCOUNT": "AFS1",
      //   "VENDOR #": 1045376,
      //   "ARTICLE #": 1009461,
      //   "WBS #": "01.02.11",
      //   "QTY": 4869.43,
      //   "UNIT COST [$]": 1,
      //   "PO #": null,
      //   "TAX": 0.068
      //  },
      //  {
      //   "ACCOUNT": "AFS1",
      //   "VENDOR #": 1045376,
      //   "ARTICLE #": 1009461,
      //   "WBS #": "01.02.11",
      //   "QTY": 2345.67,
      //   "UNIT COST [$]": 1,
      //   "PO #": null,
      //   "TAX": 0.068
      //  },
      //   to: e.g.
      //    {
      //   "ACCOUNT": "AFS1",
      //   "VENDOR #": 1045376,
      //   "ARTICLE #": 1009461,
      //   "details": [
      //     {
      //       "WBS #": "01.02.11",
      //       "QTY": 4869.43,
      //       "UNIT COST [$]": 1,
      //       "PO #": null,
      //       "TAX": 0.068
      //     },
      //     {
      //       "WBS #": "01.02.11",
      //       "QTY": 2345.67,
      //       "UNIT COST [$]": 1,
      //       "PO #": null,
      //       "TAX": 0.068
      //     },
      //   ]
      // }

      const transformedItems = itemsToSave.reduce((acc: any[], item) => {
        const key = `${item["ACCOUNT"]}-${item["VENDOR #"]}-${item["ARTICLE #"]}`;

        const existingEntry = acc.find(
          (entry) =>
            entry["ACCOUNT"] === item["ACCOUNT"] &&
            entry["VENDOR #"] === item["VENDOR #"] &&
            entry["ARTICLE #"] === item["ARTICLE #"]
        );

        const details = {
          "WBS #": item["WBS #"],
          QTY: item["QTY"],
          "UNIT COST [$]": item["UNIT COST [$]"],
          TAX: item["TAX"],
        };

        if (existingEntry) {
          // If the entry already exists, push the new details
          existingEntry.details.push(details);
        } else {
          // Create a new entry with the details
          acc.push({
            ACCOUNT: item["ACCOUNT"],
            "VENDOR #": item["VENDOR #"],
            "ARTICLE #": item["ARTICLE #"],
            details: [details],
          });
        }

        return acc;
      }, []);

      const postData = {
        ...currentVersion,
        form_details: { ...formDetailsToSave, ...newDetailsToSave } as FormDetails,
        items: transformedItems,
      };

      try {
        const response = await axios.post(
          `${BASE_API}/cofProjects/update/`,
          postData,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        console.log("Dati salvati:", response.data);
        setNewFormDetail(null);
        setNewItem(null);
        setNewDetails([]);
        await fetchData();  // Refetch data after saving
      } catch (error) {
        console.error("Errore durante il salvataggio dei dati:", error);
      }
    }
  };

  const handleInputChange = (
    version: number,
    index: number,
    key: keyof Item,
    value: string,
  ) => {
    setEditableItems((prevItems) => {
      const newItems = { ...prevItems };
      newItems[version] = [...newItems[version]];
      newItems[version][index] = {
        ...newItems[version][index],
        [key]:
          typeof newItems[version][index][key] === "number" ? parseFloat(value) : value,
      };
      return newItems;
    });
  };

  const handleNewItemChange = (key: keyof Item, value: string) => {
    setNewItem((prevItem) => ({
      ...prevItem,
      [key]: typeof prevItem?.[key] === "number" ? parseFloat(value) : value,
    }));
  };

  const handleNewFormDetailChange = (key: keyof FormDetails, value: string) => {
    setNewFormDetail((prevDetail) => ({
      ...prevDetail,
      [key]: typeof prevDetail?.[key] === "number" ? parseFloat(value) : value,
    }));
  };

  const handleNewDetailChange = (index: number, key: keyof { name: string; value: string }, value: string) => {
    setNewDetails((prevDetails) => {
      const newDetails = [...prevDetails];
      newDetails[index] = { ...newDetails[index], [key]: value };
      return newDetails;
    });
  };

  const handleExportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const allFormDetails = [
      data?.data.current_version.form_details,
      ...(data?.data.archive_versions || []).map(
        (version) => version.form_details
      ),
    ].filter(Boolean) as FormDetails[];

    const formDetailsSheets = allFormDetails.map((formDetails, index) => {
      return XLSX.utils.json_to_sheet([formDetails]);
    });
    formDetailsSheets.forEach((sheet, index) => {
      XLSX.utils.book_append_sheet(
        wb,
        sheet,
        `Form_Details_Version_${index + 1}`
      );
    });

    let allItems: any[] = [];
    if (data?.data.current_version.items) {
      allItems = data.data.archive_versions.reduce((acc, version) => {
        return [...acc, ...version.items];
      }, data.data.current_version.items);
    }

    const itemsSheet = XLSX.utils.json_to_sheet(allItems);
    XLSX.utils.book_append_sheet(wb, itemsSheet, "Items");

    data?.data.archive_versions.forEach((version, index) => {
      const versionItemsSheet = XLSX.utils.json_to_sheet(version.items);
      XLSX.utils.book_append_sheet(
        wb,
        versionItemsSheet,
        `Items_Version_${index + 1}`
      );
    });

    XLSX.writeFile(wb, "project_data.xlsx");
  };

  const handleAddDetail = () => {
    setNewDetails([...newDetails, { name: "", value: "" }]);
  };

  const handleVersionClick = (version: VersionDetails | ArchiveVersion) => {
    if (isVersionSelected) {
      setSelectedVersions(
        [data!.data.current_version.version, ...data!.data.archive_versions.map((v: { version: any }) => v.version)]
      );
    } else {
      setSelectedVersions([version.version]);
    }
    setIsVersionSelected(!isVersionSelected);
  };

  if (!data) return <div>Loading...</div>;

  const versions: (VersionDetails | ArchiveVersion)[] = [
    data.data.current_version,
    ...data.data.archive_versions,
  ].sort((a, b) => b.version - a.version);

  const commonFields = ["ACCOUNT", "ARTICLE #", "VENDOR #"];
  const versionSpecificFields = Object.keys(versions[0].items[0]).filter(
    (key) => !commonFields.includes(key)
  );

  const displayedVersions = versions.filter((version) => selectedVersions.includes(version.version));

  const commonItems = displayedVersions.reduce((acc, version) => {
    version.items.forEach((item, idx) => {
      if (!acc[idx]) acc[idx] = {};
      commonFields.forEach(field => {
        acc[idx][field] = item[field];
      });
    });
    return acc;
  }, [] as { [key: string]: string | number }[]);

  const versionSpecificItems = displayedVersions.map((version) =>
    version.items.map((item) =>
      versionSpecificFields.reduce((acc, field) => {
        acc[field] = item[field];
        return acc;
      }, {} as { [key: string]: string | number })
    )
  );

  console.log("Common Items:", commonItems);
  console.log("Version Specific Items:", versionSpecificItems);

  return (
    <div>
      <button className="applyButton" onClick={handleExportToExcel}>
        EXPORT
      </button>
      <button className="applyButton" onClick={handleSave}>
        SAVE
      </button>

      <table id="infos" style={{ marginBottom: "0", width: "80%", margin: "0 auto" }}>
        <thead>
          <tr>
            <th></th>
            {displayedVersions.map((version, index) => (
              <th
                key={index}
                onClick={() => handleVersionClick(version)}
                style={{ cursor: "pointer" }}
              >
                Version {version.version}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(data.data.current_version.form_details).map((key, index) => (
            <tr key={index}>
              <td>{key}</td>
              {displayedVersions.map((version, vIndex) => (
                <td key={vIndex}>
                  <CellValue
                    value={editableFormDetails[version.version]?.[key as keyof FormDetails]}
                    onChange={(newValue) =>
                      setEditableFormDetails((prevState) => ({
                        ...prevState,
                        [version.version]: {
                          ...prevState[version.version],
                          [key]: typeof editableFormDetails[version.version]?.[key as keyof FormDetails] === "number"
                            ? parseFloat(newValue)
                            : newValue,
                        }
                      }))
                    }
                    type={typeof editableFormDetails[version.version]?.[key as keyof FormDetails] === "number" ? "number" : "text"}
                  />
                </td>
              ))}
            </tr>
          ))}
          {newFormDetail && (
            <tr>
              {Object.keys(newFormDetail).map((key, index) => (
                <td key={index}>
                  <CellValue
                    value={newFormDetail[key as keyof FormDetails]}
                    onChange={(newValue) => handleNewFormDetailChange(key as keyof FormDetails, newValue)}
                    type={typeof newFormDetail[key as keyof FormDetails] === "number" ? "number" : "text"}
                  />
                </td>
              ))}
            </tr>
          )}
          {newDetails.map((detail, index) => (
            <tr key={index}>
              <td>
                <input
                  type="text"
                  value={detail.name}
                  onChange={(e) => handleNewDetailChange(index, "name", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={detail.value}
                  onChange={(e) => handleNewDetailChange(index, "value", e.target.value)}
                />
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={displayedVersions.length + 1} style={{ textAlign: "center" }}>
              <button onClick={handleAddDetail} style={{ border: "none", background: "none", color: "blue", textDecoration: "underline", cursor: "pointer" }}>
                ADD DETAILS +
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <table
        id="data"
        style={{ borderCollapse: "collapse", width: "80%", margin: "0 auto" }}
      >
        <thead>
          <tr>
            <th colSpan={commonFields.length}>
             {/*} !expanded ? (
                <button
                  onClick={() => {
                    setExpanded(true);
                    setRowsToShow(displayedVersions[0].items.length);
                  }}
                  style={{ border: "none", background: "none", color: "blue", textDecoration: "underline", cursor: "pointer" }}
                >
                  EXPAND
                </button>
              ) : (
                <button
                  onClick={() => {
                    setExpanded(false);
                    setRowsToShow(5);
                  }}
                  style={{ border: "none", background: "none", color: "blue", textDecoration: "underline", cursor: "pointer" }}
                >
                  REDUCE
                </button>
              )}*/}
            </th>
            {displayedVersions.map((version, index) => (
              <th
                key={index}
                colSpan={versionSpecificFields.length}
                style={{ padding: "8px", cursor: "pointer" }}
                onClick={() => handleVersionClick(version)}
              >
                Version {version.version}
              </th>
            ))}
          </tr>
          <tr>
            {commonFields.map((field, index) => (
              <th key={index} style={{ padding: "8px" }}>
                {field}
              </th>
            ))}
            {displayedVersions.map((version, versionIndex) =>
              versionSpecificFields.map((key, keyIndex) => (
                <th key={`${versionIndex}-${keyIndex}`} style={{ padding: "8px" }}>
                  {key}
                </th>
              ))
            )}
          </tr>
        </thead>
        <tbody>
          {commonItems.map((commonItem, idx) => (
            <tr key={idx}>
              {commonFields.map((field, fieldIndex) => (
                <td key={fieldIndex} style={{ padding: "8px" }}>
                  {commonItem[field]}
                </td>
              ))}
              {displayedVersions.map((version, versionIndex) =>
                versionSpecificFields.map((field, keyIndex) => (
                  <td key={`${version.version}-${field}-${idx}`} style={{ padding: "8px" }}>
                    <CellValue
                      value={editableItems[version.version]?.[idx]?.[field] ?? versionSpecificItems[versionIndex][idx][field]}
                      onChange={(newValue) => handleInputChange(version.version, idx, field as keyof Item, newValue)}
                      type={typeof versionSpecificItems[versionIndex][idx][field] === "number" ? "number" : "text"}
                    />
                  </td>
                ))
              )}
            </tr>
          ))}
          {newItem && (
            <tr>
              {commonFields.map((field, fieldIndex) => (
                <td key={fieldIndex} style={{ padding: "8px" }}>
                  <CellValue
                    value={newItem[field]}
                    onChange={(newValue) => handleNewItemChange(field as keyof Item, newValue)}
                    type="text"
                  />
                </td>
              ))}
              {versionSpecificFields.map((field, keyIndex) => (
                <td key={keyIndex} style={{ padding: "8px" }}>
                  <CellValue
                    value={newItem[field]}
                    onChange={(newValue) => handleNewItemChange(field as keyof Item, newValue)}
                    type={typeof newItem[field] === "number" ? "number" : "text"}
                  />
                </td>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const CellValue: React.FC<{
  value: string | number | undefined;
  onChange: (newValue: string) => void;
  type: "text" | "number";
}> = ({ value, onChange, type }) => {
  return (
    <input
      type={type}
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default CofProjectDati;
