import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './component/Login/Login';
import Search from './component/Search/Search';
import Upload from './component/Upload/Upload';
import Table from './component/TableAPI/CofProjectTable';
import VersionPage from './component/TableAPI/VersionCOF';
import CofProjectDati from './component/TableAPI/CofProjectDati';

function App() {
    return (
        <div className="App">
            <Router>

                <Routes>
                    <Route path='/' element={<Login />} />

                    <Route path='/search' element={<Search />} />
                    <Route path="/project/version/:version" Component={VersionPage} />

                    <Route path='/upload' element={<Upload />} />


                </Routes>




            </Router>

        </div>
    );
}

export default App;
