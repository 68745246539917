import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Projects } from './CofProjectTable';

const VersionCOF = () => {
  const { version } = useParams();
  const [projects, setProjects] = useState<Projects[]>([]);
  const [additionalHeaders, setAdditionalHeaders] = useState<string[]>([]);
  const sortedProjects = [...projects].sort((a, b) => b.version - a.version);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<{ data: Projects[] }>(`http://localhost:3002/cofProjects/list/?version=${version}`);;
        
        //filtra la versione esatta da renderizzare
        const filteredProjects = response.data.data.filter(project => project.version.toString() === version);
        setProjects(filteredProjects);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [version]);


  //start logica di aggiunta header
  const addHeader = () => {
    const newHeader = prompt('Enter new header name:');
    if (newHeader) {
      setAdditionalHeaders([...additionalHeaders, newHeader]);
    }
  };


  //logica per apply modifiche in input all'interno della table
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, projectId: string, fieldName: string) => {
    const updatedProjects = projects.map((project) => {
      if (project._id === projectId) {
        return {
          ...project,
          form_details: {
            ...project.form_details,
            [fieldName]: e.target.value,
          },
        };
      }
      return project;
    });
    setProjects(updatedProjects);
  };

  //cost che permette di renderizzare tutti i campi all'interno di form_datails(forse provvisoria se si trovano altre soluzioni)
  const allFormDetailKeys: string[] = Array.from(new Set(sortedProjects.flatMap(project => Object.keys(project.form_details))));
  return (
    <div>
      <h1>Cof Project {version}</h1>

      <table>
        <thead>
          <tr>
            <th></th>
            {sortedProjects.map((project, index) => (
              <th>Version {project.version}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allFormDetailKeys.map((fieldName) => (
            <tr key={fieldName}>
              <td>{fieldName}</td>
              {sortedProjects.map((project) => (
                <td key={project._id}>
                  <input
                    type="text"
                    value={project.form_details[fieldName] || ''} // Mostra una stringa vuota se il campo non esiste
                    onChange={(e) => handleInputChange(e, project._id, fieldName)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <button className="buttonHeader" onClick={addHeader}>
        ADD HEADER
      </button>
    </div>
  );
};

export default VersionCOF;
